import type { User, UserImage, Project, ProjectImage } from 'startup-proto/startuptsnode/startup/msg/db/v1/db_pb'

// XXX: Named export is not support by startup-proto grpc_tools_node_protoc_plugin yet.
import pb from 'startup-proto/startuptsnode/startup/msg/db/v1/enum_pb'
const { UserImageEnum, ProjectImageEnum } = pb

export const getUserIcon = (elm: User.AsObject): UserImage.AsObject | undefined => {
  const image = elm?.imagesList?.find((r) => r.imageType === UserImageEnum.ImageType.ICON)

  if (!image) {
    return undefined
  }

  return image
}

export const getProjectIcon = (elm: Project.AsObject): ProjectImage.AsObject | undefined => {
  const image = elm?.imagesList?.find((r) => r.imageType === ProjectImageEnum.ImageType.ICON)

  if (!image) {
    return undefined
  }

  return image
}

export const getProjectBackgroundImage = (elm: Project.AsObject): ProjectImage.AsObject | undefined => {
  const image = elm?.imagesList?.find((r) => r.imageType === ProjectImageEnum.ImageType.NONE)

  if (!image) {
    return undefined
  }

  return image
}
